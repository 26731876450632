import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { DeploymentsPageComponent } from './common/list/deployments-page.component';
import { FhirRunningGuard } from './guards/fhir-running.guard';
import { RunningGuard } from './guards/running.guard';
import { HCCRunningGuard } from './guards/hcc-running.guard';
import { MessageBanksGuard } from './guards/message-banks.guard';
import { ConfigurationsComponent } from './hmts/configurations/configurations.component';
import { HmtsRunningGuard } from './guards/hmts-running.guard';
import { SqlaasRunningGuard } from './guards/sqlaas-running.guard';
import { IccaGuard } from './guards/icca.guard';
import { SdsRunningGuard } from './guards/sds-running.guard';
import { SdsDocumentationRedirect } from './sds/sds-documentation-redirect.component';
import { LolRunningGuard } from './guards/lol-running.guard';

import { IRISAuthGuard } from './guards/iris-auth.guard';
import { IRISLoginPageComponent } from './icca-common/components/iris-login/iris-login-page.component';
import { HSPDRunningGuard } from './guards/hspd-running.guard';
import { SqlaasDocumentationRedirect } from './sqlaas/sqlaas-documentation-redirect.component';
import { IccaTroubleshootingRedirect } from './icca-common/components/icca-troubleshooting-redirect.component';
import { SQLQueryComponent } from './sqlaas/sql-query/sql-query.component';
import { ImportComponent } from './sqlaas/import/import.component';
import { IMLToolsComponent } from './sqlaas/iml-tools/iml-tools.component';
import { IccaUploadedFilesComponent } from './icca-common/components/uploaded-files/uploaded-files.component';
import { FhirServerRunningGuard } from './guards/fhirserver-running.guard';
import { IccaRunningGuard } from './guards/icca-running.guard';
import { LiveGuard } from './guards/live.guard';
import { DocRunningGuard } from './guards/doc-running.guard';
import { DeploymentValidGuard } from './guards/deployment-valid.guard';
import { FhirSqlRunningGuard } from './guards/fhir-sql-running.guard';
import { FirewallRunningGuard } from './guards/firewall-running-guard';
import { ICSRunningGuard } from './guards/ics-running.guard';
import { SPCRunningGuard } from './guards/spc-running.guard';
import { FilesRunningGuard } from './guards/files-running-guard';
import { Business360RunningGuard } from './guards/business360.guard';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

export const routes: Routes = [
  {
    path: '',
    component: DeploymentsPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'create',
    loadChildren: () => import('./common/create/create.module').then(m => m.CreateModule),
    pathMatch: 'full',
  },
  {
    path: 'create/:demo',
    loadChildren: () => import('./common/create/create.module').then(m => m.CreateModule),
    pathMatch: 'full',
  },
  {
    path: ':deploymentId/list',
    redirectTo: '/deployments',
    data: {
      caption: 'List',
    },
  },
  {
    path: ':deploymentId/overview',
    loadChildren: () => import('./common/overview/overview.module').then(m => m.OverviewModule),
    pathMatch: 'full',
    canActivate: [DeploymentValidGuard],
    data: {
      caption: 'Overview',
    },
  },
  {
    path: ':deploymentId/dns',
    loadChildren: () => import('./common/split-dns/split-dns.module').then(m => m.SplitDnsModule),
    pathMatch: 'full',
    canActivate: [DeploymentValidGuard],
    data: {
      caption: 'DNS Forwarding'
    }
  },
  {
    path: ':deploymentId/fhirmetrics',
    loadChildren: () => import('./fhir/metrics/metrics.module').then(m => m.MetricsModule),
    pathMatch: 'full',
    canActivate: [FhirRunningGuard],
    data: {
      caption: 'Metrics',
    },
  },
  {
    path: ':deploymentId/oauth',
    loadChildren: () => import('./fhir/o-auth/o-auth.module').then(m => m.OAuthModule),
    pathMatch: 'full',
    canActivate: [FhirRunningGuard],
    data: {
      caption: 'OAuth 2.0',
    },
  },
  {
    path: ':deploymentId/credentials',
    loadChildren: () => import('./fhir/credentials/credentials.module').then(m => m.CredentialsModule),
    pathMatch: 'full',
    canActivate: [FhirRunningGuard],
    data: {
      caption: 'Credentials',
    },
  },
  {
    path: ':deploymentId/apidevelopment',
    loadChildren: () => import('./fhir/api-development/api-development.module').then(m => m.ApiDevelopmentModule),
    pathMatch: 'full',
    canActivate: [FhirRunningGuard],
    data: {
      caption: 'API Development',
    },
  },
  {
    path: ':deploymentId/accesslogs',
    loadChildren: () => import('./fhir/access-logs/access-logs.module').then(m => m.AccessLogsModule),
    pathMatch: 'full',
    canActivate: [FhirRunningGuard],
    data: {
      caption: 'Access Logs',
    },
  },
  {
    path: ':deploymentId/datamanagement',
    loadChildren: () => import('./fhir/data-management/data-management.module').then(m => m.DataManagementModule),
    pathMatch: 'full',
    canActivate: [FhirRunningGuard],
    data: {
      caption: 'Data Management',
    },
  },
  {
    path: ':deploymentId/resources',
    loadChildren: () => import('./fhir/resources/resources.module').then(m => m.ResourcesModule),
    pathMatch: 'full',
    canActivate: [FhirRunningGuard],
    data: {
      caption: 'Resources',
      adminOnly: true,
    },
  },
  {
    path: ':deploymentId/backup',
    loadChildren: () => import('./common/backup/backup.module').then(m => m.BackupModule),
    pathMatch: 'full',
    canActivate: [RunningGuard],
    data: {
      caption: 'Backup',
    },
  },
  {
    path: ':deploymentId/packages',
    loadChildren: () => import('./fhir/packages/packages.module').then(m => m.PackagesModule),
    pathMatch: 'full',
    canActivate: [FhirRunningGuard],
    data: {
      caption: 'Packages',
    },
  },
  {
    path: ':deploymentId/systemmanagement',
    loadChildren: () => import('./common/systemmanagement/systemmanagement.module').then(m => m.SystemmanagementModule),
    pathMatch: 'full',
    canActivate: [ICSRunningGuard],
    data: {
      caption: 'System Management',
    },
  },
  {
    path: ':deploymentId/business360portal',
    loadChildren: () =>
      import('./business360/systemmanagement/systemmanagement.module').then(m => m.B360SystemmanagementModule),
    pathMatch: 'full',
    canActivate: [Business360RunningGuard],
    data: {
      caption: 'Data Fabric Studio Portal',
    },
  },
  {
    path: ':deploymentId/systemmanagementhspd',
    loadChildren: () => import('./common/systemmanagement/systemmanagement.module').then(m => m.SystemmanagementModule),
    pathMatch: 'full',
    canActivate: [HSPDRunningGuard],
    data: {
      caption: 'System Management',
    },
  },
  {
    path: ':deploymentId/connectusers',
    loadChildren: () => import('./common/connect-users/connect-users.module').then(m => m.ConnectUsersModule),
    pathMatch: 'full',
    canActivate: [ICSRunningGuard],
    data: {
      caption: 'Connect Users',
    },
  },
  {
    path: ':deploymentId/productions',
    loadChildren: () => import('./common/productions/productions.module').then(m => m.ProductionsModule),
    pathMatch: 'full',
    canActivate: [ICSRunningGuard],
    data: {
      caption: 'Productions',
    },
  },
  {
    path: ':deploymentId/ssltlsconfigurations',
    loadChildren: () =>
      import('./common/ssl-tls-configurations/ssl-tls-configuration.module').then(m => m.SslTlsConfigurationModule),
    pathMatch: 'full',
    canActivate: [ICSRunningGuard],
    data: {
      caption: 'SSL/TLS Configurations',
    },
  },
  {
    path: ':deploymentId/sqlgateway',
    loadChildren: () => import('./common/sql-gateway/sql-gateway.module').then(m => m.SqlGatewayModule),
    pathMatch: 'full',
    canActivate: [ICSRunningGuard],
    data: {
      caption: 'SQL Gateways',
    },
  },
  {
    path: ':deploymentId/firewall',
    loadChildren: () => import('./common/firewall/firewall.module').then(m => m.FirewallModule),
    pathMatch: 'full',
    canActivate: [FirewallRunningGuard],
    data: {
      caption: 'Firewall',
      adminOnly: false,
    },
  },
  {
    path: ':deploymentId/banks',
    loadChildren: () => import('./common/banks/banks.module').then(m => m.BanksModule),
    pathMatch: 'full',
    canActivate: [MessageBanksGuard],
    data: {
      caption: 'Message Banks',
    },
  },
  {
    path: ':deploymentId/sqlbuilderconfiguration',
    loadChildren: () =>
      import('./sqlbuilder/configuration/configuration.module').then(m => m.SqlbuilderConfigurationModule),
    canActivate: [FhirSqlRunningGuard],
    data: {
      caption: 'SQL Builder',
    },
  },
  {
    path: ':deploymentId/configurations',
    component: ConfigurationsComponent,
    pathMatch: 'full',
    canActivate: [HmtsRunningGuard],
    data: {
      caption: 'Configurations',
    },
  },
  {
    path: ':deploymentId/filesbucket',
    loadChildren: () => import('./hmts/files-bucket/files-bucket.module').then(m => m.FilesBucketModule),
    pathMatch: 'full',
    canActivate: [FilesRunningGuard],
    data: {
      caption: 'Files',
    },
  },
  {
    path: ':deploymentId/lols',
    loadChildren: () => import('./lol/lolconfigurations.module').then(m => m.LolConfigurationsModule),
    pathMatch: 'full',
    canActivate: [LolRunningGuard],
    data: {
      caption: 'Configurations',
    },
  },
  {
    path: ':deploymentId/errors',
    loadChildren: () => import('./hmts/errors/errors.module').then(m => m.HmtsErrorsModule),
    pathMatch: 'full',
    canActivate: [HmtsRunningGuard],
    data: {
      caption: 'Errors',
    },
  },
  {
    path: ':deploymentId/metrics',
    loadChildren: () => import('./hmts/metrics/metrics.module').then(m => m.MetricsModule),
    pathMatch: 'full',
    canActivate: [HmtsRunningGuard],
    data: {
      caption: 'Metrics',
    },
  },
  {
    path: ':deploymentId/hccmetrics',
    loadChildren: () => import('./hcc/metrics/metrics.module').then(m => m.HccMetricsModule),
    pathMatch: 'full',
    canActivate: [HCCRunningGuard],
    data: {
      caption: 'Metrics',
      adminOnly: true,
    },
  },
  {
    path: ':deploymentId/cicd',
    resolve: {
      url: externalUrlProvider,
    },
    pathMatch: 'full',
    canActivate: [HCCRunningGuard, LiveGuard],
    data: {
      caption: 'CI/CD Pipeline',
      externalUrl: 'https://scm.dev.isccloud.io/',
    },
  },

  {
    path: ':deploymentId/filemanagement',
    //loadChildren: () => import('./sqlaas/import/uploaded-files/uploaded-files.module').then(m => m.UploadedFilesModule),
    component: IccaUploadedFilesComponent,
    pathMatch: 'full',
    canActivate: [IccaRunningGuard, IRISAuthGuard],
    data: {
      caption: 'Add and Manage Files',
      route: 'filemanagement',
    },
  },

  {
    path: ':deploymentId/import',
    //loadChildren: () => import('./sqlaas/import/import.module').then(m => m.ImportModule),
    component: ImportComponent,
    pathMatch: 'full',
    canActivate: [IccaRunningGuard, IRISAuthGuard],
    data: {
      caption: 'Import Files',
    },
  },
  {
    path: ':deploymentId/docimport',
    loadChildren: () => import('./doc/import/import.module').then(m => m.DocImportModule),
    pathMatch: 'full',
    canActivate: [DocRunningGuard, IRISAuthGuard],
    data: {
      caption: 'Collection Import',
    },
  },
  {
    path: ':deploymentId/sqlquery',
    component: SQLQueryComponent,
    pathMatch: 'full',
    canActivate: [IccaRunningGuard, IRISAuthGuard],
    data: {
      caption: 'SQL Query Tools',
    },
  },
  {
    path: ':deploymentId/iccaprocesses',
    loadChildren: () => import('./icca-common/components/processes/processes.module').then(m => m.IccaProcessesModule),
    pathMatch: 'full',
    canActivate: [IccaRunningGuard, IRISAuthGuard],
    data: {
      caption: 'SQL Processes',
    },
  },
  {
    path: ':deploymentId/iml',
    //loadChildren: () => import('./sqlaas/iml-tools/iml-tools.module').then(m => m.IMLToolsModule),
    component: IMLToolsComponent,
    pathMatch: 'full',
    canActivate: [SqlaasRunningGuard, IRISAuthGuard],
    data: {
      caption: 'IntegratedML Tools',
    },
  },
  {
    path: ':deploymentId/doccollectionbrowser',
    loadChildren: () =>
      import('./doc/collection-browser/collection-browser.module').then(m => m.DocCollectionBrowserModule),
    pathMatch: 'full',
    canActivate: [DocRunningGuard, IRISAuthGuard],
    data: {
      caption: 'Collection Browser',
    },
  },
  {
    path: ':deploymentId/iccabackup',
    loadChildren: () => import('./icca-common/components/backup/backup.module').then(m => m.IccaBackupModule),
    pathMatch: 'full',
    canActivate: [IccaGuard],
    data: {
      caption: 'Restore Data Backup',
    },
  },
  {
    path: ':deploymentId/irislogin',
    component: IRISLoginPageComponent,
    pathMatch: 'full',
    canActivate: [DeploymentValidGuard, IccaRunningGuard],
    data: {
      caption: 'InterSystems IRIS Cloud Login',
    },
  },
  //fhirserver
  {
    path: ':deploymentId/fhiroauth',
    loadChildren: () => import('./fhirserver/o-auth/o-auth.module').then(m => m.FhirOAuthModule),
    pathMatch: 'full',
    canActivate: [FhirServerRunningGuard],
    data: {
      caption: 'OAuth 2.0',
    },
  },
  {
    path: ':deploymentId/fhircredentials',
    loadChildren: () => import('./fhirserver/credentials/credentials.module').then(m => m.FhirCredentialsModule),
    pathMatch: 'full',
    canActivate: [FhirServerRunningGuard],
    data: {
      caption: 'Credentials',
    },
  },
  {
    path: ':deploymentId/fhirdatamanagement',
    loadChildren: () =>
      import('./fhirserver/data-management/data-management.module').then(m => m.FhirDataManagementModule),
    pathMatch: 'full',
    canActivate: [FhirServerRunningGuard, IRISAuthGuard],
    data: {
      caption: 'Data Management',
    },
  },
  //documentation
  {
    path: ':deploymentId/fhirdocumentation',
    canActivate: [FhirRunningGuard],
    data: {
      caption: 'Documentation',
      openUrl: 'https://docs.intersystems.com/services/csp/docbook/DocBook.UI.Page.cls?KEY=PAGE_fas',
    },
  },
  {
    path: ':deploymentId/hmtsdocumentation',
    canActivate: [HmtsRunningGuard],
    data: {
      caption: 'Documentation',
      openUrl: 'https://docs.intersystems.com/services/csp/docbook/DocBook.UI.Page.cls?KEY=PAGE_fts',
    },
  },
  {
    path: ':deploymentId/hccdocumentation',
    canActivate: [HCCRunningGuard],
    data: {
      caption: 'Documentation',
      openUrl: 'https://docs.intersystems.com/services/csp/docbook/DocBook.UI.Page.cls?KEY=PAGE_hcc',
    },
  },
  {
    path: ':deploymentId/iccatroubleshooting',
    component: IccaTroubleshootingRedirect,
    canActivate: [IccaGuard],
    data: {
      caption: 'Troubleshooting',
    },
  },
  {
    path: ':deploymentId/sqlaasdocumentation',
    component: SqlaasDocumentationRedirect,
    canActivate: [IccaGuard],
    data: {
      caption: 'Documentation',
    },
  },
  {
    path: ':deploymentId/sdsdocumentation',
    component: SdsDocumentationRedirect,
    canActivate: [SdsRunningGuard],
    data: {
      caption: 'Documentation',
    },
  },
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.data.externalUrl;
        window.open(externalUrl, '_blank');
        throw 'do not follow';
      },
    },
  ],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeploymentsRoutingModule {}
